<template>
    <div class="modal fade" id="goToQuestionModal" tabindex="-1" aria-labelledby="goToQuestionModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="goToQuestionModalLabel">Ir Para Pergunta</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="goToQuestion">
              <div class="mb-3">
                <label for="questionNumber" class="form-label">Quantidade de Perguntas</label>
                <input type="number" class="form-control" id="questionNumber" v-model="questionOffset" min="1" :max="maxQuestions" required>
              </div>
              <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                <button type="submit" class="btn btn-primary">Ir Para</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'GoToQuestionModal',
    props: {
      maxQuestions: Number
    },
    data() {
      return {
        questionOffset: 1
      };
    },
    methods: {
      goToQuestion() {
        this.$emit('goToQuestion', this.questionOffset);
        this.simulateCancelClick();
      },
      simulateCancelClick() {
        const cancelButton = document.querySelector('#goToQuestionModal .btn-secondary');
        if (cancelButton) {
          cancelButton.click();
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-content {
    background-color: #fff;
    border-radius: 0.3rem;
  }
  </style>
  