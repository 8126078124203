<template>
  <div class="modal fade" id="quizParametersModal" tabindex="-1" aria-labelledby="quizParametersModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="quizParametersModalLabel">Parâmetros do Quiz</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <button class="btn btn-danger mb-3" @click="handleResetQuiz" data-bs-dismiss="modal">Reiniciar Quiz</button>
          <button class="btn btn-warning mb-3" @click="handleResetClassifications" data-bs-dismiss="modal">Reiniciar Classificação</button>
          <!-- Hidden: Exibir perguntas de forma aleatória -->
          <!-- <div class="form-check">
            <input 
              class="form-check-input" 
              type="checkbox" 
              id="shuffleQuestions" 
              v-model="localShuffleQuestions"
            >
            <label class="form-check-label" for="shuffleQuestions">
              Exibir perguntas de forma aleatória
            </label>
          </div> -->
          <div class="mt-3">
            <p>Filtrar por classificação:</p>
            <div class="form-check">
              <input 
                class="form-check-input" 
                type="checkbox" 
                id="filterEasy" 
                v-model="localSelectedDifficulties" 
                value="Fácil"
              >
              <label class="form-check-label" for="filterEasy">Fácil</label>
            </div>
            <div class="form-check">
              <input 
                class="form-check-input" 
                type="checkbox" 
                id="filterMedium" 
                v-model="localSelectedDifficulties" 
                value="Média"
              >
              <label class="form-check-label" for="filterMedium">Média</label>
            </div>
            <div class="form-check">
              <input 
                class="form-check-input" 
                type="checkbox" 
                id="filterHard" 
                v-model="localSelectedDifficulties" 
                value="Difícil"
              >
              <label class="form-check-label" for="filterHard">Difícil</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
          <button type="button" class="btn btn-primary" @click="applyParameters">Aplicar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default {
  name: 'QuizParametersModal',
  props: {
    shuffleQuestions: Boolean,
    selectedDifficulties: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localShuffleQuestions: this.shuffleQuestions,
      localSelectedDifficulties: [...this.selectedDifficulties]
    };
  },
  watch: {
    shuffleQuestions(newVal) {
      this.localShuffleQuestions = newVal;
    },
    selectedDifficulties(newVal) {
      if (Array.isArray(newVal)) {
        this.localSelectedDifficulties = [...newVal];
      } else {
        this.localSelectedDifficulties = [];
      }
    }
  },
  methods: {
    applyParameters() {
      // Check if no filters or all filters are selected, then treat it as no filter
      if (this.localSelectedDifficulties.length === 0 || this.localSelectedDifficulties.length === 3) {
        this.$emit('update:selectedDifficulties', []);
      } else {
        this.$emit('update:selectedDifficulties', this.localSelectedDifficulties);
      }
      this.$emit('update:shuffleQuestions', this.localShuffleQuestions);
      const modalElement = document.getElementById('quizParametersModal');
      if (modalElement) {
        modalElement.querySelector('[data-bs-dismiss="modal"]').click();
      }
    },
    handleResetQuiz() {
      this.$emit('resetQuiz');
    },
    handleResetClassifications() {
      this.$emit('resetClassifications');
    }
  }
};
</script>

<style scoped>
.modal-content {
  background-color: #fff;
  border-radius: 0.3rem;
}
</style>
