<template>
  <div id="app">
    <div v-if="loading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <AuthStatusDisplay />
      <router-view />
    </div>
  </div>
</template>

<script>
import AuthStatusDisplay from './components/AuthStatusDisplay.vue';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';

export default {
  name: 'App',
  components: {
    AuthStatusDisplay
  },
  data() {
    return {
      loading: true
    };
  },
  created() {
    onAuthStateChanged(auth, () => {
      this.loading = false;
    });
  }
};
</script>

<style>
@import 'bootstrap/dist/css/bootstrap.min.css';
</style>
