<!-- src/components/HomePage.vue -->
<template>
  <div>
    <h1>Home</h1>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
};
</script>
