<template>
  <div class="card mb-4 position-relative">
    <div class="badge-container">
      <span class="badge badge-primary">{{ question.ID }}</span>
    </div>
    <div class="card-body">
      <h5 class="card-title">{{ question.question }}</h5>
      <p class="card-text">{{ question.choose }}</p>
      <div v-for="(resp, index) in shuffledResponses" :key="index" class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          :value="resp.resp1"
          v-model="selectedResponses"
          :id="'response' + index"
          :disabled="result !== null"
        >
        <label
          class="form-check-label d-flex align-items-center"
          :for="'response' + index"
          :class="{'correct-answer': result !== null && resp.correct}"
        >
          {{ resp.resp1 }}
          <span v-if="result !== null && resp.correct" class="ms-2 text-success">&#10003;</span>
        </label>
      </div>
      <div class="mt-3">
        <p>Classifique esta pergunta:</p>
        <div class="btn-group" role="group" aria-label="Difficulty rating">
          <button type="button" class="btn btn-secondary" @click="setDifficulty('Fácil')" :class="{'btn-success': difficulty === 'Fácil'}">Fácil</button>
          <button type="button" class="btn btn-secondary" @click="setDifficulty('Média')" :class="{'btn-warning': difficulty === 'Média'}">Média</button>
          <button type="button" class="btn btn-secondary" @click="setDifficulty('Difícil')" :class="{'btn-danger': difficulty === 'Difícil'}">Difícil</button>
        </div>
      </div>
      <button class="btn btn-primary mt-3" @click="checkAnswer" :disabled="result !== null">Exibir Resultado</button>
      <p v-if="result !== null" :class="result ? 'text-success' : 'text-danger'">{{ result ? 'Correto!' : 'Incorreto!' }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizQuestion',
  props: {
    question: Object,
    savedResponses: Array,
    savedResult: Boolean,
    savedDifficulty: String
  },
  data() {
    return {
      selectedResponses: [],
      shuffledResponses: [],
      result: null,
      difficulty: this.savedDifficulty || '' // Inicializa com vazio se não tiver classificação
    };
  },
  watch: {
    question: {
      immediate: true,
      handler(newQuestion) {
        if (newQuestion) {
          this.resetQuestion(newQuestion);
        }
      }
    }
  },
  mounted() {
    if (this.question) {
      this.resetQuestion(this.question);
    }
  },
  methods: {
    shuffle(array) {
      return array.sort(() => Math.random() - 0.5);
    },
    resetQuestion(newQuestion) {
      this.selectedResponses = this.savedResponses || [];
      this.shuffledResponses = this.shuffle(newQuestion.response);
      this.result = this.savedResult;
      this.difficulty = this.savedDifficulty || '';
    },
    checkAnswer() {
      const correctAnswers = this.question.response.filter(resp => resp.correct).map(resp => resp.resp1);
      this.result = this.selectedResponses.length === correctAnswers.length &&
                    this.selectedResponses.every(resp => correctAnswers.includes(resp));
      this.$emit('updateScore', this.result);
      this.$emit('saveResponses', this.selectedResponses);
    },
    setDifficulty(difficulty) {
      this.difficulty = difficulty;
      this.$emit('saveDifficulty', difficulty);
    }
  }
};
</script>

<style scoped>
.correct-answer {
  font-weight: bold;
}
.badge-container {
  position: absolute;
  top: -20px;
  left: 20px;
}
.badge {
  background-color: black;
  color: white;
  font-size: 1rem;
  padding: 0.5em;
  border-radius: 50%;
}
</style>
