import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import LoginForm from '../components/LoginForm.vue';
import RegisterForm from '../components/RegisterForm.vue';
import QuizApp from '../components/QuizApp.vue';
import { getAuth } from 'firebase/auth';

const routes = [
  { path: '/', component: HomePage },
  { path: '/login', component: LoginForm },
  { path: '/register', component: RegisterForm },
  { path: '/quiz', component: QuizApp, meta: { requiresAuth: true } }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (requiresAuth && !user) {
    next('/login');
  } else if (!requiresAuth && user && (to.path === '/login' || to.path === '/register')) {
    next('/quiz');
  } else {
    next();
  }
});

export default router;
