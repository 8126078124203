<template>
  <nav class="navbar navbar-light bg-light mb-4">
    <div class="container-fluid">
      <span class="navbar-brand mb-0 h1">Quiz Progress</span>
      <div class="progress w-50">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{ width: progressPercentage + '%' }"
          :aria-valuenow="progressPercentage"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <span class="navbar-text">
        {{ answeredQuestions }}/{{ totalQuestions }} - {{ scorePercentage }}%
      </span>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'QuizHeader',
  props: {
    answeredQuestions: {
      type: Number,
      required: true
    },
    totalQuestions: {
      type: Number,
      required: true
    },
    scorePercentage: {
      type: Number,
      required: true
    }
  },
  computed: {
    progressPercentage() {
      return (this.answeredQuestions / this.totalQuestions) * 100;
    }
  }
};
</script>

<style scoped>
.progress-bar {
  transition: width 0.6s ease;
}
</style>
