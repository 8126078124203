// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDSMs4Ag7M71-hbn909_577F_g7XkOB47I",
    authDomain: "sapcertificationedmardev.firebaseapp.com",
    projectId: "sapcertificationedmardev",
    storageBucket: "sapcertificationedmardev.appspot.com",
    messagingSenderId: "246637362010",
    appId: "1:246637362010:web:2cd0877958294e3cb91163",
    measurementId: "G-9KFECE2DZW"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { db, auth };