<template>
  <div class="auth-status">
    <div v-if="user" class="alert alert-success" role="alert">
      <p>Welcome, {{ user.email }}</p>
      <button class="btn btn-secondary" @click="logout">Logout</button>
    </div>
    <div v-else class="alert alert-warning" role="alert">
      <p>You are not logged in</p>
      <router-link class="btn btn-primary me-2" to="/login">Login</router-link>
      <router-link class="btn btn-primary" to="/register">Register</router-link>
    </div>
  </div>
</template>

<script>
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

export default {
  name: 'AuthStatusDisplay',
  data() {
    return {
      user: null
    };
  },
  created() {
    onAuthStateChanged(auth, (user) => {
      this.user = user;
    });
  },
  methods: {
    async logout() {
      try {
        await signOut(auth);
        localStorage.clear();
        this.$router.push('/login');
      } catch (error) {
        console.error('Erro ao fazer logout:', error);
      }
    }  }
};
</script>

<style scoped>
.auth-status {
  margin-top: 20px;
  text-align: center;
}
</style>
